dmx.Component('s3-upload', {

  initialData: {
    data: null,
    file: null,
    state: {
      idle: true,
      ready: false,
      uploading: false,
      done: false,
    },
    uploadProgress: {
      position: 0,
      total: 0,
      percent: 0,
    },
    lastError: {
      status: 0,
      message: '',
      response: null,
    },
  },

  attributes: {
    url: {
      type: String,
      default: null,
    },

    prop: {
      type: String,
      default: 'url',
    },

    accept: {
      type: String,
      default: null,
    },

    autoupload: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    abort () {
      this.abort();
    },

    reset () {
      this.reset();
    },

    select () {
      this._input.click();
    },

    upload () {
      this.upload();
    },
  },

  events: {
    start: Event, // when starting an ajax call
    done: Event, // when ajax call completed (success and error)
    error: Event, // server error or javascript error (json parse or network transport) or timeout error
    abort: Event, // ajax call was aborted
    success: Event, // successful ajax call,
    upload: ProgressEvent, // on upload progress
  },

  init () {
    this._abortHandler = this._abortHandler.bind(this);
    this._errorHandler = this._errorHandler.bind(this);
    this._timeoutHandler = this._timeoutHandler.bind(this);
    this._loadHandler = this._loadHandler.bind(this);
    this._progressHandler = this._progressHandler.bind(this);
    this._dragoverHandler = this._dragoverHandler.bind(this);
    this._dropHandler = this._dropHandler.bind(this);
    this._clickHandler = this._clickHandler.bind(this);
    this._changeHandler = this._changeHandler.bind(this);

    this._xhr = new XMLHttpRequest();
    this._xhr.addEventListener('abort', this._abortHandler);
    this._xhr.addEventListener('error', this._errorHandler);
    this._xhr.addEventListener('timeout', this._timeoutHandler);
    this._xhr.addEventListener('load', this._loadHandler);
    this._xhr.upload.addEventListener('progress', this._progressHandler);
  },

  render (node) {
    this.$node.addEventListener('dragover', this._dragoverHandler);
    this.$node.addEventListener('drop', this._dropHandler);
    this.$node.addEventListener('click', this._clickHandler);

    this._input = document.createElement('input');
    this._input.type = 'file';
    this._input.accept = this.props.accept || '*/*';
    this._input.addEventListener('change', this._changeHandler);

    this.$parse();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('accept')) {
      this._input.accept = this.props.accept || '*/*';
    }
  },

  destroy () {
    this._xhr.removeEventListener('abort', this._abortHandler);
    this._xhr.removeEventListener('error', this._errorHandler);
    this._xhr.removeEventListener('timeout', this._timeoutHandler);
    this._xhr.removeEventListener('load', this._loadHandler);
    this._xhr.upload.removeEventListener('progress', this._progressHandler);

    this.$node.removeEventListener('dragover', this._dragoverHandler);
    this.$node.removeEventListener('drop', this._dropHandler);
    this.$node.removeEventListener('click', this._clickHandler);

    this._input.removeEventListener('change', this._changeHandler);

    this._xhr = null;
    this._input = null;
  },

  _validate (file) {
    if (this.props.accept) {
      return this.props.accept.split(/\s*,\s*/g).some((type) => {
        if (type.charAt(0) == '.') {
          if (file.name.match(new RegExp('\\' + type + '$', 'i'))) {
            return true;
          }
        } else if (/(audio|video|image)\/\*/i.test(type)) {
          if (file.type.match(new RegExp('^' + type.replace(/\*/g, '.*') + '$', 'i'))) {
            return true;
          }
        } else {
          if (file.type.toLowerCase() == type.toLowerCase()) {
            return true;
          }
        }

        return false;
      });
    }

    return true;
  },

  updateFile (file) {
    if (!this._validate(file)) {
      return;
    }

    const info = {
      name: file.name,
      size: file.size,
      type: file.type,
      date: (file.lastModified ? new Date(file.lastModified) : file.lastModifiedDate).toISOString(),
      dataUrl: null,
    };

    if (file.type.indexOf('image/') !== -1 && !file.reader) {
      file.reader = new FileReader();

      file.reader.onload = (e) => {
        info.dataUrl = e.target.result;
        this.set('file', {...info});
      };

      file.reader.readAsDataURL(file);
    }

    this.file = file;

    this.set({
      file: info,
      state: {
        idle: false,
        ready: true,
        uploading: false,
        done: false,
      },
    });

    if (this.props.autoupload) {
      this.upload();
    }
  },

  abort () {
    this._xhr.abort();
  },

  reset () {
    this.abort();
    this.file = null;
    this.set({
      data: null,
      file: null,
      state: {
        idle: true,
        ready: false,
        uploading: false,
        done: false,
      },
      uploadProgress: {
        position: 0,
        total: 0,
        percent: 0,
      },
      lastError: {
        status: 0,
        message: '',
        response: null,
      },
    });
  },

  upload () {
    if (!this.props.url) {
      this.onError('No url attribute is set');
      return;
    }

    this.set({
      state: {
        idle: false,
        ready: false,
        uploading: true,
        done: false,
      },
    });

    this.dispatchEvent('start');

    const xhr = new XMLHttpRequest();
    xhr.onabort = this._abortHandler;
    xhr.onerror = this._errorHandler;
    xhr.onload = () => this.upload2(xhr);
    xhr.open('GET', this.props.url + '?name=' + encodeURIComponent(this.file.name));
    xhr.send();
  },

  upload2 (xhr) {
    try {
      const data = JSON.parse(xhr.responseText);
      const url = data[this.props.prop];
      this.set('data', data);
      this._xhr.open('PUT', url);
      this._xhr.setRequestHeader('Content-Type', this.file.type);
      if (url.indexOf('x-amz-acl=') != -1) {
        // could be improved
        var acl = url.substr(url.indexOf('x-amz-acl=') + 10);
        if (acl.indexOf('&') != -1) acl = acl.substr(0, acl.indexOf('&'));
        this._xhr.setRequestHeader('x-amz-acl', acl);
      }
      this._xhr.send(this.file);
    } catch (err) {
      this._errorHandler(err);
    }
  },

  _abortHandler (event) {
    this.set({
      data: null,
      state: {
        idle: false,
        ready: true,
        uploading: false,
        done: false,
      },
      uploadProgress: {
        position: 0,
        total: 0,
        percent: 0,
      },
    });

    this.dispatchEvent('abort');
    this.dispatchEvent('done');
  },

  _errorHandler (event) {
    if (event instanceof ProgressEvent) {
      event = 'Network error, perhaps no CORS set';
    }

    this.set({
      data: null,
      state: {
        idle: false,
        ready: true,
        uploading: false,
        done: false,
      },
      uploadProgress: {
        position: 0,
        total: 0,
        percent: 0,
      },
      lastError: {
        status: 0,
        message: event.message || event,
        response: null,
      },
    });

    console.error(event);

    this.dispatchEvent('error');
    this.dispatchEvent('done');
  },

  _timeoutHandler (event) {
    this._errorHandler('Execution timeout');
  },

  _loadHandler (event) {
    if (this._xhr.status >= 400) {
      this._errorHandler(this._xhr.responseText);
    } else {
      this.set({
        state: {
          idle: false,
          ready: false,
          uploading: false,
          done: true,
        },
        uploadProgress: {
          position: this.file.size,
          total: this.file.size,
          percent: 100,
        },
      });

      this.dispatchEvent('success');
      this.dispatchEvent('done');
    }
  },

  _progressHandler (event) {
    this.set({
      state: {
        idle: false,
        ready: false,
        uploading: true,
        done: false,
      },
      uploadProgress: {
        position: event.loaded,
        total: this.file.size,
        percent: Math.ceil((event.loaded / event.total) * 100),
      },
    });

    this.dispatchEvent('upload', {
      lengthComputable: event.lengthComputable,
      loaded: event.loaded,
      total: event.total,
    });
  },

  _dragoverHandler (event) {
    event.stopPropagation();
    event.preventDefault();

    event.dataTransfer.dropEffect = event.dataTransfer.items.length == 1 ? 'copy' : 'none';
  },

  _dropHandler (event) {
    event.stopPropagation();
    event.preventDefault();

    if (event.dataTransfer.files.length == 1) {
      this.updateFile(event.dataTransfer.files[0]);
    }
  },

  _clickHandler (event) {
    this._input.click();
  },

  _changeHandler (event) {
    this.updateFile(event.target.files[0]);
    this._input.value = '';
    this._input.type = '';
    this._input.type = 'file';
  },

});
